import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Card,
} from 'react-bootstrap';
import './App.css';

function useStateLocalStorage<T>(key: string, def: T): [T, (t: T) => void] {
  const objFromStorage = localStorage.getItem(key);
  const init = objFromStorage ? JSON.parse(objFromStorage).val : def;
  const [val, setVal] = useState(init);
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify({ val }));
  }, [val, key]);
  return [val, setVal];
}

function displayCurrency(money: number): string {
  return money.toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
  });
}

interface LoanProps {
  index: number;
}

const Loan: React.FC<LoanProps> = (props) => {
  const {
    index,
  } = props;
  const [rate, setRate] = useStateLocalStorage(`${index}-rate`, 1);
  const [principal, setPrincipal] = useStateLocalStorage(`${index}-principal`, 0);
  const [owedInterest, setOwedInterest] = useStateLocalStorage(`${index}-owedInterest`, 0);
  const [simulatedPayment, setSimulatedPayment] = useStateLocalStorage(`${index}-simulatedPayment`, 0);
  const percentage = rate / 100.0;

  const perYear = percentage * principal;
  const perDay = perYear / 365.0;

  const simulatedOwedInterest = owedInterest - simulatedPayment;
  const displaySimulatedOwedInterest = Math.max(0, simulatedOwedInterest);
  const simulatedPrincipal = Math.min(principal, simulatedOwedInterest + principal);

  const simulatedPerYear = percentage * simulatedPrincipal;
  const simulatedPerDay = simulatedPerYear / 365.0;

  const savingsPerDay = perDay - simulatedPerDay;
  const savingsPerYear = perYear - simulatedPerYear;
  return (
    <Card border="secondary">
      <Card.Header>Loan {index+1}</Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <p>Terms</p>
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text>Rate</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="number"
                value={rate}
                onChange={(e) => setRate(Number(e.target.value))}
              />
            </InputGroup>
            <InputGroup className="mt-1" size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  Principal
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="number"
                value={principal}
                onChange={(e) => setPrincipal(Number(e.target.value))}
              />
            </InputGroup>
            <InputGroup className="mt-1" size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  Owed Interest
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="number"
                value={owedInterest}
                onChange={(e) => setOwedInterest(Number(e.target.value))}
              />
            </InputGroup>
          </Col>
          <Col>
            <p>Interest Accrued (compounded daily)</p>
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  Per Day
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                disabled
                value={displayCurrency(perDay)}
              />
            </InputGroup>
            <InputGroup className="mt-1" size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  Per Year
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                disabled
                value={displayCurrency(perYear)}
              />
            </InputGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p>Simulate Payment</p>
            <InputGroup className="mt-1" size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text className="bg-info text-white">
                  Payment
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="number"
                value={simulatedPayment}
                onChange={(e) => setSimulatedPayment(Number(e.target.value))}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <p>New Terms</p>
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text>Rate</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl value={rate} disabled />
            </InputGroup>
            <InputGroup className="mt-1" size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  Principal
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                disabled
                value={displayCurrency(simulatedPrincipal)}
              />
            </InputGroup>
            <InputGroup className="mt-1" size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  Owed Interest
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                disabled
                value={displayCurrency(displaySimulatedOwedInterest)}
              />
            </InputGroup>
          </Col>
          <Col>
            <p>New Interest Accrued</p>
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  Per Day
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                disabled
                value={displayCurrency(simulatedPerDay)}
              />
            </InputGroup>
            <InputGroup className="mt-1" size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  Per Year
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                disabled
                value={displayCurrency(simulatedPerYear)}
              />
            </InputGroup>
          </Col>
          <Col>
            <p>Interest Savings</p>
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text className="text-white bg-success">
                  Per Day
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                disabled
                value={displayCurrency(savingsPerDay)}
              />
            </InputGroup>
            <InputGroup className="mt-1" size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text className="text-white bg-success">
                  Per Year
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                disabled
                value={displayCurrency(savingsPerYear)}
              />
            </InputGroup>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function App() {
  const [num, setNum] = useStateLocalStorage('numLoans', 0);
  return (
    <Container>
      <Row className="h-100">
        <Col>
          <h1>Student Loan Calculator</h1>
          <InputGroup className="mt-1" size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text className="bg-primary text-white">
                Number of Loans
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="number"
              value={num}
              onChange={(e) => setNum(Number(e.target.value))}
            />
          </InputGroup>
          <div>
            {Array(num).fill(0).map((_, i) => (
              <div key={i} className="my-4">
                <Loan index={i} />
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
